import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translation'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/:locale',
    beforeEnter: Trans.routeMiddleware,
    component: {
      template: "<router-view></router-view>"
    },
    children: [
      {
        path: '',
        name: 'root',
        component: load('SelectEtab')
      },
      {
        path: ':etab',
        component: load('Layout'),
        props: true,
        children: [
          { 
            name: 'home',
            path: '', 
            component: load('Home'),
            props: true 
          },
          {
            path: 'precheckin/:identifier?',
            name: 'precheckin',
            component: load('Precheckin'),
            props: true,
            meta: { transitionName: 'zoom' },
          },
          {
            path: 'survey/:code?',
            name: 'survey',
            component: load('Survey'),
            props: true,
            meta: { transitionName: 'zoom' },
          },
          {
            path: ':page',
            name: 'page',
            component: load('Page'),
            props: true,
            meta: { transitionName: 'zoom' },

          },
          
        ]
      },
    ]
  },
  {
    path: '*',
    redirect() {
      return Trans.getUserSupportedLocale();
    }
  }

  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
