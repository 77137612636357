import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    etabs: [],
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    allEtabs: state => state.etabs,
    banner: state => state.banner,
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  mutations: {
    GET_ETABS: (state, etabs) => {
      state.etabs = etabs
    }
  },
  actions: {
    getEtabs({commit}){
      axios.get('/etabs').then(response => {
        commit('GET_ETABS', response.data)
      })
    }
  },
  modules: {
  }
})
