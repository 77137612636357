import Vue from 'vue'
import App from './App.vue'
import { i18n } from '@/setup/i18n-setup'
import router from './router'
import VueAnalytics from 'vue-analytics'
import VueSession from 'vue-session'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { Trans } from '@/plugins/Translation'
import moment from 'moment'
import "./vee-validate";

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  if (value) {
    if(this.$i18n.locale == "fr"){
      return moment(String(value)).format('DD/MM/YYYY à hh:mm')
    }else{
      return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
    
  }
})
Vue.use(VueSession)
Vue.use(VueAnalytics, {
  id: 'UA-103999891-2',
  disabled:  localStorage.disableTracking || true,
  router
})

window.csrfToken = document.querySelector('meta[name="csrf-token"]').content;
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
